@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500&display=swap');

a, body, div, p, span {
    font-family: Rubik,sans-serif!important;
}
body{
    background-color: #f4f6fc!important;
    color: #2E384D!important;
    font-family: 'Rubik', sans-serif!important;
}

.text-muted {
    color: #8798AD !important;
}
.custom-scrollbar{
    overflow: auto;
}
.custom-scrollbar::-webkit-scrollbar,.table-responsive::-webkit-scrollbar,body::-webkit-scrollbar {
    height: 6px;
    width:  6px;
}
/*Track */
.custom-scrollbar::-webkit-scrollbar-track,.table-responsive::-webkit-scrollbar-track,body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
}
/*Handle */
.custom-scrollbar::-webkit-scrollbar-thumb,.table-responsive::-webkit-scrollbar-thumb,body::-webkit-scrollbar-thumb {
    background: #0037bf42;
    border-radius: 6px;
    box-shadow: inset 0 0 5px 3px #0037bf;
}
.custom-scrollbar::-webkit-scrollbar-thumb:hover, .table-responsive::-webkit-scrollbar-thumb:hover, body::-webkit-scrollbar-thumb:hover {
    background: #0037bf8f;
    scrollbar-color: #0037bf8f #0037bf8f;
}
.dashboardCard .card-custom{
    border-radius: 0.42rem;
    overflow: hidden;
}
.dashboardCard .card-custom:hover .bgIcon {
    transform: scale(1.1);
}
.holdreq {
    background-image: linear-gradient( 230deg, #759bff, #843cf6);
}
.confhistory {
    background-image: linear-gradient( 135deg, #ffc480, #ff763b);
}
.salehistory{
    background-image: linear-gradient( 230deg, #0e4cfd, #6a8eff);
}
.activestone{
    background-image: linear-gradient( 230deg, #26A69A, #44d4c7);
}
.activestone .bgIcon{
    right: -42px;
}
.invalidstone{
    background-image: linear-gradient( 132deg, #293134, #5c6a70);
}
.invalidstone .bgIcon{
    color: rgba(0,0,0,.25);
}
.viewprofile{
    background-image: linear-gradient( 126deg, #ff4444, #ff9191);
}
.bgIcon {
    color: rgba(0,0,0,.15);
    z-index: 0;
    position: absolute;
    font-size: 107px;
    top: -5px;
    right: -32px;
    transition: all .3s linear;
}
.holdreq .svg-icon-white{
    color: rgba(0,0,0,.15);
}

.aside-menu .menu-nav > .menu-separator {
    border-bottom: 1px solid #a2a3b7a3!important;
}
.label.label-light-Pending {
    color: #FFA800;
    background-color: #FFF4DE;
}
.label.label-light-Approve {
    color: #1BC5BD;
    background-color: #C9F7F5;
}
.label.label-light-Release {
    color: #F64E60;
    background-color: #FFE2E5;
}
.label.label-light-Cancel {
    color: #F64E60;
    background-color: #FFE2E5;
}


/* CSS ICON ALERT GENERAL */
.screenAlert-icon{border-radius:50%;border:4px solid gray;box-sizing:content-box;height:80px;margin:20px auto;padding:0;position:relative;width:80px}
.screenAlert-icon + .screenAlert-icon {margin-top:50px}

/* CSS SUCCESS */
.screenAlert-icon.screenAlert-success{border-color:#1bc5bd33}
.screenAlert-icon.screenAlert-success:after,
.screenAlert-icon.screenAlert-success:before{background-color:transparent!important;content:'';height:120px;position:absolute;-webkit-transform:rotate(45deg);transform:rotate(45deg);width:60px}
.screenAlert-icon.screenAlert-success:before{border-radius:120px 0 0 120px;left:-33px;top:-7px;-webkit-transform-origin:60px 60px;transform-origin:60px 60px;-webkit-transform:rotate(-45deg);transform:rotate(-45deg)}
.screenAlert-icon.screenAlert-success:after{border-radius:0 120px 120px 0;left:30px;top:-11px;-webkit-transform-origin:0 60px;transform-origin:0 60px;-webkit-transform:rotate(-45deg);transform:rotate(-45deg)}
.screenAlert-icon.screenAlert-success .screenAlert-placeholder{border-radius:50%;border:4px solid #1bc5bd33;box-sizing:content-box;height:80px;left:-4px;position:absolute;top:-4px;width:80px;z-index:2}
.screenAlert-icon.screenAlert-success .screenAlert-line{background-color:#1bc5bd;border-radius:2px;display:block;height:5px;position:absolute;z-index:2}
.screenAlert-icon.screenAlert-success .screenAlert-line.screenAlert-tip{left:14px;top:46px;-webkit-transform:rotate(45deg);transform:rotate(45deg);width:25px}
.screenAlert-icon.screenAlert-success .screenAlert-line.screenAlert-long{right:8px;top:38px;-webkit-transform:rotate(-45deg);transform:rotate(-45deg);width:47px}
.animateSuccessTip{-webkit-animation:animateSuccessTip .75s;animation:animateSuccessTip .75s}
.animateSuccessLong{-webkit-animation:animateSuccessLong .75s;animation:animateSuccessLong .75s}
/* ANIMATE SUCCESS */
@-webkit-keyframes animateSuccessTip{0%,54%{width:0;left:1px;top:19px}70%{width:50px;left:-8px;top:37px}84%{width:17px;left:21px;top:48px}100%{width:25px;left:14px;top:45px}}
@keyframes animateSuccessTip{0%,54%{width:0;left:1px;top:19px}70%{width:50px;left:-8px;top:37px}84%{width:17px;left:21px;top:48px}100%{width:25px;left:14px;top:45px}}
@-webkit-keyframes animateSuccessLong{0%,65%{width:0;right:46px;top:54px}84%{width:55px;right:0;top:35px}100%{width:47px;right:8px;top:38px}}
@keyframes animateSuccessLong{0%,65%{width:0;right:46px;top:54px}84%{width:55px;right:0;top:35px}100%{width:47px;right:8px;top:38px}}


/* CSS ALERT */
.screenAlert-icon.screenAlert-error{border-color:#F27474}
.screenAlert-icon.screenAlert-error:after,
.screenAlert-icon.screenAlert-error:before{background-color:transparent!important;content:'';height:120px;position:absolute;-webkit-transform:rotate(45deg);transform:rotate(45deg);width:60px}
.screenAlert-icon.screenAlert-error:before{border-radius:120px 0 0 120px;left:-33px;top:-7px;-webkit-transform-origin:60px 60px;transform-origin:60px 60px;-webkit-transform:rotate(-45deg);transform:rotate(-45deg)}
.screenAlert-icon.screenAlert-error:after{border-radius:0 120px 120px 0;left:30px;top:-11px;-webkit-transform-origin:0 60px;transform-origin:0 60px;-webkit-transform:rotate(-45deg);transform:rotate(-45deg)}
.screenAlert-icon.screenAlert-error .screenAlert-placeholder{border-radius:50%;border:4px solid rgba(165,220,134,0.2);box-sizing:content-box;height:80px;left:-4px;position:absolute;top:-4px;width:80px;z-index:2}
.screenAlert-icon.screenAlert-error .screenAlert-line{background-color:#A5DC86;border-radius:2px;display:block;height:5px;position:absolute;z-index:2}
.screenAlert-icon.screenAlert-error .screenAlert-line.screenAlert-tip{left:14px;top:46px;-webkit-transform:rotate(45deg);transform:rotate(45deg);width:25px}
.screenAlert-icon.screenAlert-error .screenAlert-line.screenAlert-long{right:8px;top:38px;-webkit-transform:rotate(-45deg);transform:rotate(-45deg);width:47px}
.screenAlert-icon.screenAlert-error .screenAlert-x-mark{display:block;position:relative;z-index:2}
.screenAlert-icon.screenAlert-error .screenAlert-placeholder{border:4px solid rgba(200,0,0,0.2)}
.screenAlert-icon.screenAlert-error .screenAlert-line{background-color:#F27474;top:37px;width:47px}
.screenAlert-icon.screenAlert-error .screenAlert-line.screenAlert-left{left:17px;-webkit-transform:rotate(45deg);transform:rotate(45deg)}
.screenAlert-icon.screenAlert-error .screenAlert-line.screenAlert-right{right:16px;-webkit-transform:rotate(-45deg);transform:rotate(-45deg)}
.animateXLeft{-webkit-animation:animateXLeft .75s;animation:animateXLeft .75s}
.animateXRight{-webkit-animation:animateXRight .75s;animation:animateXRight .75s}
/* ANIMATE CROSS X */
@-webkit-keyframes animateXLeft{0%,65%{left:82px;top:95px;width:0}84%{left:14px;top:33px;width:47px}100%{left:17px;top:37px;width:47px}}
@keyframes animateXLeft{0%,65%{left:82px;top:95px;width:0}84%{left:14px;top:33px;width:47px}100%{left:17px;top:37px;width:47px}}
@-webkit-keyframes animateXRight{0%,65%{right:82px;top:95px;width:0}84%{right:14px;top:33px;width:47px}100%{right:16px;top:37px;width:47px}}
@keyframes animateXRight{0%,65%{right:82px;top:95px;width:0}84%{right:14px;top:33px;width:47px}100%{right:16px;top:37px;width:47px}}

/* CSS WARNING */
.screenAlert-icon.screenAlert-warning{border-color:#F8BB86}
.screenAlert-icon.screenAlert-warning:before{-webkit-animation:pulseWarning 2s linear infinite;animation:pulseWarning 2s linear infinite;background-color:transparent!important;border-radius:50%;content:"";display:inline-block;height:100%;opacity:0;position:absolute;width:100%}
.screenAlert-icon.screenAlert-warning:after{background-color:transparent!important;border-radius:50%;content:'';display:block;height:100%;position:absolute;width:100%;z-index:1}
.screenAlert-icon.screenAlert-warning .screenAlert-body{background-color:#F8BB86;border-radius:2px;height:47px;left:50%;margin-left:-2px;position:absolute;top:10px;width:5px;z-index:2}
.screenAlert-icon.screenAlert-warning .screenAlert-dot{background-color:#F8BB86;border-radius:50%;bottom:10px;height:7px;left:50%;margin-left:-3px;position:absolute;width:7px;z-index:2}
.scaleWarning{-webkit-animation:scaleWarning .75s infinite alternate;animation:scaleWarning .75s infinite alternate}
.pulseWarningIns{-webkit-animation:pulseWarningIns .75s infinite alternate;animation:pulseWarningIns .75s infinite alternate}
/* ANIMATE WARNING */
@-webkit-keyframes scaleWarning{0%{-webkit-transform:scale(1);transform:scale(1)}30%{-webkit-transform:scale(1.02);transform:scale(1.02)}100%{-webkit-transform:scale(1);transform:scale(1)}}
@keyframes scaleWarning{0%{-webkit-transform:scale(1);transform:scale(1)}30%{-webkit-transform:scale(1.02);transform:scale(1.02)}100%{-webkit-transform:scale(1);transform:scale(1)}}
@-webkit-keyframes pulseWarning{0%{background-color:#fff;-webkit-transform:scale(1);transform:scale(1);opacity:.5}30%{background-color:#fff;-webkit-transform:scale(1);transform:scale(1);opacity:.5}100%{background-color:#F8BB86;-webkit-transform:scale(2);transform:scale(2);opacity:0}}
@keyframes pulseWarning{0%{background-color:#fff;-webkit-transform:scale(1);transform:scale(1);opacity:.5}30%{background-color:#fff;-webkit-transform:scale(1);transform:scale(1);opacity:.5}100%{background-color:#F8BB86;-webkit-transform:scale(2);transform:scale(2);opacity:0}}
@-webkit-keyframes pulseWarningIns{0%{background-color:#F8D486}100%{background-color:#F8BB86}}
@keyframes pulseWarningIns{0%{background-color:#F8D486}100%{background-color:#F8BB86}}

/* CSS INFO */
.screenAlert-icon.screenAlert-info{border-color:#9de0f6}
.screenAlert-icon.screenAlert-info:before{-webkit-animation:pulseInfo 2s linear infinite;animation:pulseInfo 2s linear infinite;background-color:transparent!important;border-radius:50%;content:"";display:inline-block;height:100%;opacity:0;position:absolute;width:100%}
.screenAlert-icon.screenAlert-info:after{background-color:transparent!important;border-radius:50%;content:'';display:block;height:100%;position:absolute;width:100%;z-index:1}
.screenAlert-icon.screenAlert-info .screenAlert-body{background-color:#3fc3ee;border-radius:2px;height:47px;left:50%;margin-left:-2px;position:absolute;top:10px;width:5px;z-index:2}
.screenAlert-icon.screenAlert-info .screenAlert-dot{background-color:#3fc3ee;border-radius:50%;bottom:10px;height:7px;left:50%;margin-left:-3px;position:absolute;width:7px;z-index:2}
.scaleInfo{-webkit-animation:scaleInfo .75s infinite alternate;animation:scaleInfo .75s infinite alternate}
.pulseInfoIns{-webkit-animation:pulseInfoIns .75s infinite alternate;animation:pulseInfoIns .75s infinite alternate}
/* ANIMATE INFO */
@-webkit-keyframes scaleInfo{0%{-webkit-transform:scale(1);transform:scale(1)}30%{-webkit-transform:scale(1.02);transform:scale(1.02)}100%{-webkit-transform:scale(1);transform:scale(1)}}
@keyframes scaleInfo{0%{-webkit-transform:scale(1);transform:scale(1)}30%{-webkit-transform:scale(1.02);transform:scale(1.02)}100%{-webkit-transform:scale(1);transform:scale(1)}}
@-webkit-keyframes pulseInfo{0%{background-color:#fff;-webkit-transform:scale(1);transform:scale(1);opacity:.5}30%{background-color:#fff;-webkit-transform:scale(1);transform:scale(1);opacity:.5}100%{background-color:#9de0f6;-webkit-transform:scale(2);transform:scale(2);opacity:0}}
@keyframes pulseInfo{0%{background-color:#fff;-webkit-transform:scale(1);transform:scale(1);opacity:.5}30%{background-color:#fff;-webkit-transform:scale(1);transform:scale(1);opacity:.5}100%{background-color:#9de0f6;-webkit-transform:scale(2);transform:scale(2);opacity:0}}
@-webkit-keyframes pulseInfoIns{0%{background-color:#3fc3ee}100%{background-color:#9de0f6}}
@keyframes pulseInfoIns{0%{background-color:#3fc3ee}100%{background-color:#9de0f6}}
.table.table-hover tbody tr:hover {
    background-color: #eff3f680;
}
.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.03);
}
.custom_datatable_head{
    font-family: 'Rubik', sans-serif;
    background: #f3f5f9
}
.salehistory_table.table{
    min-width: 1200px;  
}
/* .table{
    min-width: 1520px;  
}
.kycTable.table, .permissionTable,.deleteTable .table,.api_datatable,.activeShipTable{
    min-width: auto!important;
} */
/* .deleteTable .table{
    min-width: auto!important;
}
.api_datatable{
    min-width: auto!important;
} */
.table td{
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    color:#2e384d;
    vertical-align: middle;
}
.search_result_dropdown .dropdown-toggle.nav-link:after, .dropdown-toggle.btn:after{
    content: ""!important;
}
.profileTab{
    box-shadow: 0 0 1px rgba(0,0,0,.125), 0 1px 3px rgba(0,0,0,.2)!important;
    border-radius: 0.42rem;
}
.profileTab .card.card-custom {
    box-shadow: none!important;
}
.submenu_link, span.submenu_link {
    font-size: .925rem;
    cursor: pointer;
    font-weight: 500;
}
.card.card-custom {
    box-shadow: 0 0 1px rgba(0,0,0,.125), 0 1px 3px rgba(0,0,0,.2)!important;
}
.content{
    background-color: #f4f6f9;
}
.card-header.tabbed-card{
    min-height: 50px!important;
}

.approvedApiTable tr .cancelApiBtn {
    display: none;
    transition: all .3s ease-in-out;
    margin: 0 auto;
    padding: 0.4rem 1rem;
}
.approvedApiTable tr:hover .cancelApiBtn {
    display: flex;
    box-shadow: 0 0 1px rgba(0,0,0,.125), 0 1px 3px rgba(0,0,0,.2)!important;
}
.approvedApiTable tr:hover .cancelBtnTd {
    padding: 0px;
}
.cancelBtnTd{
    width: 160px!important;
}
.profileLogo{
    width: 123px;
    height: 123px;
}
.profileLogo img{
    margin: 0 auto;
    object-fit: contain;
}
.scrolltop{
    z-index: 10000!important;
}
.color{
    display: none!important;
}
.color.show{
    display: flex!important;
    flex-wrap: wrap;  
}
.carat{
    display: none!important;
}
.carat.show{
    display: flex!important;
    flex-wrap: wrap;
}
.search_accordion .card .card-header{
    padding: 12px 26px 9px 20px!important;
    background-color: #0037bf!important;
    color: #FFF;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500;
    border-radius: 0.42rem!important
}
.search_accordion .card .card-header:after {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 16 16'%3E%3Cpath d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z' fill='%23fff'/%3E%3C/svg%3E");
    right: 19px;
    position: absolute;
}
.search_accordion .card {
    border: none!important;
}
/* .nav.nav-pills .show > .nav-link, .nav.nav-pills .nav-link.active .svg-icon svg g [fill]{
    fill:#3699FF;
} */
.search_btn_check .selectBox span{
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    display: inline-block;
    line-height:15px;
}
.search_btn_check{
    margin-bottom: 0px!important;
}
.search_btn_check > input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.search_btn_check{
    margin-bottom: 0px!important;
}
.search_btn_check > input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.search_btn_check > .selectBox {
    cursor: pointer;
    padding: 8px;
    margin: 1.5px;
    text-align: center;
    background-color: #0037bf10;
    border: 1px solid #0037bf15;
    overflow: hidden;
    border-radius: 0.42rem;
}
.search_btn_check.iframe_setup > .selectBox {
    margin: 1.5px;
    border-radius: 25px;
    background-color: #F3F5F9;
    border: 1px solid #00000000;
}

.search_btn_check.iframe_setup.cuts .finishBox.selectBox,.search_btn_check.iframe_setup.polishes .finishBox.selectBox,.search_btn_check.iframe_setup.symmetries .finishBox.selectBox,.search_btn_check.iframe_setup.milky .finishBox.selectBox {
    width: 100px;
}
.search_btn_check .shapeBox.selectBox{
    width: 85px;
    height: 70px;
}
.search_btn_check .clarityBox.selectBox{
    width: 70px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.search_btn_check .labBox.selectBox{
    width: 85px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.search_btn_check .shortcutBox.selectBox{
    width: 230px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.search_btn_check .finishBox.selectBox{
    width: 55px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.search_btn_check .fancyBox.selectBox{
    width: 120px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.search_btn_check .flourBox.selectBox{
    width: 100px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.search_btn_check .treatBox.selectBox{
    width: 180px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.search_btn_check .tingeBox.selectBox{
    width:100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.search_btn_check .shapeBox.selectBox span{
    font-size: 11px;
    padding-top: 5px;
    display: inline-block;
    width: 65px;
}

.search_btn_check > input:checked ~ .selectBox {
    background-color: #0037bf50;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .14), 0 0 2px rgba(0, 0, 0, .12);
}
.selectBox:hover {
    border:1px solid #0037bf50;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .14), 0 0 2px rgba(0, 0, 0, .12);
}

.search_btn_check > input:checked ~ .selectBox g [fill] {
    transition: fill 0.3s ease;
    stroke: #fff;
}
.search_btn_check > input:checked ~ .selectBox .triangle g [fill],.search_btn_check > input:checked ~ .selectBox .round g [fill] {
    fill: #fff;
}
.search_btn_check > input:checked ~ .selectBox span {
    transition: fill 0.3s ease;
    color: #fff;
}
.search_btn_check > .selectBox svg{
    height: 36px;
    width: auto;
}
.flagIco{
    width: 30px!important;
    /* border-radius: 5px; */
}
.img_thumb{
    text-align: center;
    list-style-type: none;
    height: 50px;
    width: 50px;
    border: 1px solid #00000010;
    border-radius: 5px;
    margin-bottom:5px;
    overflow: hidden;
    background-color: #bcbebd;
    display: flex;
    justify-content: center;
    align-items: center;
}
.img_thumb img{
    height: 48px;
    margin: 0 auto;
    object-fit: contain;
}
.img_box{
    margin-left:10px ;
    width: 350px;
    height: 300px;
    background-color: #EBEDF3;
    text-align: center;
    object-fit: cover;
}
.img_box img{
    height: 300px;
    margin: 0 auto;
    object-fit: contain;
}
.searchResult .selection, .searchResult .expander,.searchResult .Media ,.searchResult .expander-popup {
    display: none;
}
.expandedDetails{
    height: 340px;
    min-width: 1520px;
    background-color: #ffffff!important;
}
.fancyExpand{
    height: 456px!important;
}
.modal .modal-header .close span {
    display: block!important;
}
.flagIco {
    width: 20px!important;
}
.MuiInputBase-input.Mui-disabled {
    background-color: #F3F6F9;
    cursor: not-allowed;
}
.MuiStepIcon-root {
    color: #aab9cc9e;
}
.MuiSkeleton-root {
    background-color: rgb(0 0 0 / 10%)!important;
}
.Mui-focused.MuiInputLabel-outlined, .MuiFormLabel-filled.MuiInputLabel-outlined {
    background-color: #fff;
    padding: 2px 5px!important;
}
.MuiOutlinedInput-input {
    padding: 16.5px 14px!important;
}
 .MuiInputLabel-outlined {
    transform: translate(14px, 18px) scale(1);
}

/* .MuiOutlinedInput-root {
    border-radius: 0px;
} */
/* .MuiOutlinedInput-notchedOutline {
    border-color:#E4E6EF!important;
} */
.muiUploader.muiuploader-border{
    border: 1px solid #b0b1b6;
    position: relative;
}
.muiuploader-error{
    position: absolute;
    top: 55px;
    left: 17px;
    font-size: 10px;
    color: #F64E63;
}
.uploaderErrorBorder{
    border-color: #F64E63!important;
}
::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    background-image: url('data:image/svg+xml;charset=utf8,%3Csvg fill="%23000" fill-opacity=".54" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z"/%3E%3Cpath d="M0 0h24v24H0z" fill="none"/%3E%3C/svg%3E');
    width: 14px;
    height: 18px;
    cursor: pointer;
    border-radius: 50%;
    margin-left: .5rem;
  }
  .react-tel-input .form-control{
    width: 100%;
    height: 54px;
    border-radius: 4px!important;
    font-size: 13px !important;
    background-color: #ffffff!important;
    border: 1px solid rgba(202,202,202)!important;
  }
  .react-tel-input .flag-dropdown {
    border: 1px solid rgba(202,202,202)!important;
    background-color: #ffffff!important;
    border-right: none!important;
    border-radius: 4px 0 0 4px;
}
.react-tel-input .invalid-number-message {
    position: absolute!important;
    z-index: 1!important;
    font-size: 10px!important;
    left: 15px!important;
    top: 50px!important;
    background: #fff!important;
    padding: 0 2px!important;
    color: #F64E63!important;
}

.react-tel-input .form-control.invalid-number {
    border: 1px solid #F64E63!important;
    background-color: #fff!important;
    border-left-color: #cacaca;
    color: #F64E63!important;
}
.react-tel-input .flag-dropdown.invalid-number {
    border-color: #F64E63!important;
}
.custom-shadow-sm{
    box-shadow: 0 0 1px rgba(0,0,0,.125), 0 1px 3px rgba(0,0,0,.2)!important;
}

.FileMode .MuiDropzoneArea-root{
    border-color: rgb(0 55 191);
    border-radius: 15px;
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    background-color: #f4f6f9;
    border-width: 2px;
    /* box-shadow: 0 0 1px rgba(0,0,0,.125), 0 1px 3px rgba(0,0,0,.2)!important; */
    min-height: 280px;
}
/* .FileMode .MuiDropzoneArea-textContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
} */
/* .expander,.Action{
    position: sticky;
    z-index: 1099;
    left:0;
} */
.card.help-card{
    height: 270px;
}

ul.help-scrollspy{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    color: #000!important;
    font-size: 14px;
    flex-direction: column!important;
}
ul.help-scrollspy li{
    margin-left: 3px;
    color: #3F4254!important;
    margin-right: 0.25rem;
}
ul.help-scrollspy a{
    color: #000!important;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    padding: 0.75rem 1.5rem;
    position: relative;
}
ul.help-scrollspy .is-current{
    background-color: #EEF2FF!important;
    color: #000!important;
    font-weight: 500;
    border-radius: 0;
    border-left: 3px solid #2E5BFF;
    margin-left: -3px;
    transition: color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease;
}
ul.help-scrollspy li:hover{
    background-color: #EEF2FF!important;
    color: #000!important;
    font-weight: 500;
    margin-left: -3px;
    border-radius: 0;
    border-left: 3px solid #2E5BFF;
    
}
.FileMode .MuiDropzonePreviewList-removeButton{
    display: none!important;
}
.fileModeDisable{
    pointer-events: none;
}
.fileModeCursorDisable{
    cursor: not-allowed;
    opacity: 0.50;
}
.helpTable thead{
    background-color: #002173;
    color: #ffff
}
.helpTable.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(241, 244, 252, 0.82);
}
.helpTable.table tbody>tr>td:first-of-type,
.helpTable.table tbody>tr>th:first-of-type,
.helpTable.table thead>tr>td:first-of-type,
.helpTable.table thead>tr>th:first-of-type {
    width: 30%;
}
.helpTable.table{
    min-width: auto!important;
}
.contactUpload .MuiSvgIcon-root{
    display: none!important;
}
.contactUpload .MuiDropzoneArea-root{
    min-height: auto;
    border: 1px solid #E4E6EF;
    /* background-color: #00217310; */
    border: 1px solid #E4E6EF;
}
.contactUpload .MuiDropzoneArea-text{
    margin-top: 12px;
    margin-bottom: 12px;
}
.contactUpload .MuiDropzoneArea-text.MuiTypography-h5{
    font-size: 1.2rem;
}
.example.example-compact .example-toggle.example-toggled {
    background-color: #ffffff00;
}
.example .example-code .example-highlight {
    padding: 0.5rem!important;
}
.card.input-code .example-highlight > pre code {
    overflow: auto;
    max-height: 370px!important;
    scrollbar-color: #b0bac9 #b0bac950;
    scrollbar-width: thin;
}
.card.input-code .example-highlight > pre code::-webkit-scrollbar {
    width:7px;
    height: 7px;
}
/* Track */
.card.input-code .example-highlight > pre code::-webkit-scrollbar-track {
    box-shadow: inset 0 0 15px #b0bac938;
}
/* Handle */
.card.input-code .example-highlight > pre code::-webkit-scrollbar-thumb {
    background: #b0bac9;
}
.card.input-code {
    /* padding: 6px 15px; */
    background-color: #150f0f;
    color: #b0bac9;
    font-size: 14px;
    border-radius: 5px 5px 0px 0px;
}
.card.input-code .card-label{
    color: #b0bac9!important;
}
.card.input-code .card-header{
    min-height: 48px!important;
}
.card.input-code .card-body{
    padding: 0!important;
}
.card.input-code.example.example-compact .example-code {
    background-color: #F3F6F900;
    border-radius: 0!important;
}
.card.input-code .example-highlight > pre code{
    color: #b0bac9!important;
}

/* ------------------------------------------------------------------------------ */
.card.response-code .example-highlight > pre code {
    overflow: auto;
    max-height: 370px!important;
    scrollbar-color: #b0bac9 #b0bac950;
    scrollbar-width: thin;
}
.card.response-code .example-highlight > pre code::-webkit-scrollbar {
    width:7px;
    height: 7px;
}
/* Track */
.card.response-code .example-highlight > pre code::-webkit-scrollbar-track {
    box-shadow: inset 0 0 15px #b0bac938;
}
/* Handle */
.card.response-code .example-highlight > pre code::-webkit-scrollbar-thumb {
    background: #b0bac9;
}
.card.response-code .card-header{
    min-height: 48px!important;
    background-color: #c8cfda!important;
}
.card.response-code .card-body{
    padding: 0!important;
}
.card.response-code.example.example-compact .example-code {
    background-color: #b0bac938;
    border-radius: 0!important;
}
.deleteTable pre code{
    color: #e83e8c;
}
.tooltip-inner{
    max-width: 450px!important;
}
.paymentStatus .tooltip{
    /* max-width: 450px!important; */
    text-align: left!important;
}
.switch.switch-primary:not(.switch-outline) input:empty ~ span:before {
    background-color: #f64e60!important;
}

.switch.switch-warning:not(.switch-outline) input:empty ~ span:before {
    background-color: #f64e60!important;
}
.switch.switch-warning:not(.switch-outline) input:checked ~ span:before {
    background-color: #ffa800;
}
.switch.switch-primary:not(.switch-outline) input:checked ~ span:before {
    background-color: #0037bf!important;
}
.switchAccount{
    display: flex;
    color: #fff;
    padding:0px 5px;
    align-items: center;
    border-radius: 0.42rem;
    border: 1px solid #EBEDF3;
    font-size: 80%;
    font-weight: 400;
}
.aside-minimize-hover .switchAccount{
    display: flex;
}
.aside-minimize .switchAccount{
    display: none;
}
.switchDropdown button{
    background-color: transparent!important;
    border-color: transparent!important;
    padding: 0px;
    display: flex;
    justify-content: center;
}
tr.text-warning td{
    color: #FFA800 !important;
}
tr.text-danger td{
    color: #F64E60 !important;
}
tr.text-success td{
    color: #1BC5BD !important;
}

.W_diamond {
    border-radius: 50%;
    background-color: #002173;
    color: #fff;
    margin-top: 2px !important;
    height: 15px;
    width: 15px;
    line-height: 17px;
    font-size: 8px!important;
    text-align: center;
    display: inline-block;
    font-family: Rubik;
}
.F_diamond {
    border-radius: 50%;
    background-color: #bb314c;
    color: #fff;
    margin-top: 2px !important;
    height: 15px;
    width: 15px;
    line-height: 17px;
    font-size: 8px!important;
    text-align: center;
    display: inline-block;
    font-family: Rubik;
}
.L_diamond {
    border-radius: 50%;
    background-color: #00c897;
    color: #fff;
    margin-top: 2px !important;
    height: 15px;
    width: 15px;
    line-height: 17px;
    font-size: 8px!important;
    text-align: center;
    display: inline-block;
    font-family: Rubik;
}
.LF_diamond {
    border-radius: 50%;
    background-color: #f0b33f;
    color: #fff;
    margin-top: 2px !important;
    height: 15px;
    width: 15px;
    line-height: 17px;
    font-size: 8px!important;
    text-align: center;
    display: inline-block;
    font-family: Rubik;
}
.PL_diamond {
    border-radius: 50%;
    background-color: #00c897;
    color: #fff;
    margin-top: 3px !important;
    height: 18px;
    width: 18px;
    line-height: 18px;
    font-size: 10px!important;
    text-align: center;
    display: inline-block;
    font-family: Rubik;
}
.PN_diamond {
    border-radius: 50%;
    background-color: #7CCDF2;
    color: #fff;
    margin-top: 3px !important;
    height: 18px;
    width: 18px;
    line-height: 18px;
    font-size: 10px!important;
    text-align: center;
    display: inline-block;
    font-family: Rubik;
}
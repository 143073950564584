whtml {
    scroll-behavior: smooth;
  }
@font-face {
    font-family: 'ZapfHumnst';
    src: url('./fonts/Zapf-Humanist-601-BT.ttf');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'AvenirLTStdBook';
    src: url('./fonts/AvenirLTStdBook.ttf');
    font-weight: normal;
    font-style: normal;
}
body{
    background-color: #ffffff!important;
}
.navigation-clean-button {
    background: #fff;
    padding-top: .75rem;
    padding-bottom: .75rem;
    color: #333;
    border-radius: 0;
    box-shadow: none;
    border: none;
    margin-bottom: 0;
}
.nav-link{
    font-size: 16px;
}
.navbar .navbar-nav .nav-item{
    font-family: 'AvenirLTStdBook';
}
@media (max-width: 1920px) {
    .navbar-brand img {
        width: 450px !important;
    }
}

@media (max-width: 2600px) {
    .navbar-brand img {
        width: 450px !important;
    }
}

@media (max-width:425px) {
    .navbar-brand img {
        width: 270px !important;
    }
}

@media (max-width:600px) {
    .navbar-brand img {
        width: 270px !important;
    }
}

@media (max-width:300px) {
    .navbar-brand img {
        width: 270px !important;
        margin-left: 0px;
    }
}
@media (min-width:768px) {
    .navigation-clean-button {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
}

.navigation-clean-button .navbar-brand {
    font-weight: bold;
    color: inherit;
}

.navigation-clean-button .navbar-brand:hover {
    color: #222;
}

.navigation-clean-button.navbar-dark .navbar-brand:hover {
    color: #f0f0f0;
}

@media (min-width: 576px) {
    .navigation-clean-button .navbar-brand img {
        /*height: 100%;*/
        display: inline-block;
        margin-right: 10px;
        width: auto;
    }
}

.navbar-brand img {
    /*height: 10%;*/
    display: block;
    margin-right: 10px;
    /*width: auto;*/
}

.navigation-clean-button .navbar-toggler {
    border-color: #ddd;
}

.navigation-clean-button .navbar-toggler:hover,
.navigation-clean-button .navbar-toggler:focus {
    background: none;
}

.navigation-clean-button.navbar-dark .navbar-toggler {
    border-color: #555;
}

.navigation-clean-button .navbar-toggler {
    color: #888;
}

.navigation-clean-button.navbar-dark .navbar-toggler {
    color: #eee;
}

.navigation-clean-button.navbar-dark .navbar-collapse,
.navigation-clean-button.navbar-dark .form-inline {
    border-top-color: #333;
}

.navigation-clean-button .navbar-nav a.active,
.navigation-clean-button .navbar-nav>.show>a {
    background: none;
    box-shadow: none;
}

.navigation-clean-button.navbar-light .navbar-nav a.active,
.navigation-clean-button.navbar-light .navbar-nav a.active:focus,
.navigation-clean-button.navbar-light .navbar-nav {
    color: #002173;
    box-shadow: none;
    background: none;
    pointer-events: all;
}

.navigation-clean-button.navbar .navbar-nav .nav-link {

    padding-right: 18px;
}

.navigation-clean-button.navbar-light .navbar-nav .nav-link {
    color: #F0B33F;
    /* font-weight: bold; */
}

.navigation-clean-button.navbar-light .navbar-nav .nav-link:focus,
.navigation-clean-button.navbar-light .navbar-nav .nav-link:hover {
    color: #002173 !important;
    background-color: transparent;
}

.navigation-clean-button .navbar-nav>li>.dropdown-menu {
    margin-top: -5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, .1);
    background-color: #fff;
    border-radius: 2px;
}

.navigation-clean-button .dropdown-menu .dropdown-item:focus,
.navigation-clean-button .dropdown-menu .dropdown-item {
    line-height: 2;
    font-size: 14px;
    color: #37434d;
}

.navigation-clean-button .dropdown-menu .dropdown-item:focus,
.navigation-clean-button .dropdown-menu .dropdown-item:hover {
    background: #eee;
    color: inherit;
}

@media (max-width:767px) {
    .navigation-clean-button .navbar-nav .show .dropdown-menu {
        box-shadow: none;
    }
    .navigation-clean-button .navbar-nav .show .dropdown-menu .dropdown-item {
        color: #37434d;
        padding-top: 12px;
        padding-bottom: 12px;
        line-height: 1;
    }
}

.action-button {
    color: #fff!important;
    background: linear-gradient(#f0b33f, #E16E09);
    background-color: #f0b33f!important;
    border-color: #f0b33f!important;
    border-radius: 20px!important;
    padding: 5px 50px;
}

.navbar {
    position: fixed;
    top: 0;
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: .5rem 1rem;
}
.headertop {
    background-color: #1a3781;
    padding: 40px 0px;
    color: #ffffff;
    font-weight: bold;
    padding-bottom: 220px
}

.headertop h1 {
    font-size: 1.85rem;
    line-height: 1.5;
}

.headertop h1 p {
    font-size: 40px;
    margin-bottom: 1rem;
    line-height: normal;
}
.headerimg img {
    width: 300px;
    margin-top: -220px
}

.whyus img {
    width: 300px;
}
@media (min-width:527px){
    .headerimg img {
        width: 300px;
        margin-top: -200px
    }
    .whyus img {
        width: 300px !important;
    }
    .timeline::before {
        display: none;
    }
}
@media (min-width:768px){
    .headerimg img {
        width: 500px;
        margin-top: -220px
    }
    .whyus img {
        width: 350px !important;
    }
    .timeline::before {
        display: none;
    }
}
@media (min-width:770px){
    .headerimg img {
        width: 600px;
        margin-top: -220px
    }
    .timeline::before {
        display: block;
    }
    .whyus img {
        width: 450px;
    }
}

.bg-f6f8fc {
    background-color: #f6f8fc;
}
.features-boxed {
    color: #313437;
}

.features-boxed p {
    color: #7d8285;
}

.features-boxed h2 {
    font-weight: bold;
    margin-bottom: 40px;
    /*padding-top: 40px;*/
    color: inherit;
}

@media (max-width:767px) {
    .features-boxed h2 {
        margin-bottom: 25px;
        padding-top: 25px;
        font-size: 24px;
    }
}

.features-boxed .intro {
    font-size: 16px;
    max-width: 500px;
    margin: 0 auto;
}

.features-boxed .intro p {
    margin-bottom: 0;
}

.features-boxed .features {
    padding: 10px 0;
}

.features-boxed .item {
    text-align: center;
}

.features-boxed .item .box {
    text-align: center;
    /*padding: 30px;*/
    background-color: #fff;
    /*margin-bottom: 30px;*/
}

.features-boxed .item img {
    margin-top: 20px;
    margin-bottom: 20px;
}

.features-boxed .item .name {
    font-weight: bold;
    font-size: 18px;
    font-family: 'ZapfHumnst', serif;
    margin-bottom: 8px;
    margin-top: 0;
    color: #002173;
}

.features-boxed .item .description {
    font-size: 15px;
    margin-top: 15px;
    margin-bottom: 20px;
}
.banner-border {
    background: #002173;
    background: linear-gradient(to right, #002173 0%, #1a3781 34%, #efae3b 72%, #e16e09 100%);
    background: -moz-linear-gradient(left, #002173 0%, #1a3781 34%, #efae3b 72%, #e16e09 100%);
    background: -webkit-linear-gradient(left, #002173 0%, #1a3781 34%, #efae3b 72%, #e16e09 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#002173', endColorstr='#e16e09', GradientType=1);
    height: 8px;
    padding: 0;
    margin-top: 20px;
}
.howitworks h2 {
    color: #002173;
    font-family: 'ZapfHumnst', serif;
    text-align: center;
    font-size: 40px;
    margin-bottom: 20px;
}
.howitworks {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
}

.steps {
    padding: 50px 0px;
}

.steps h4 {
    color: #002173;
}
@media (max-width: 576px) {
    .btmg {
        margin: 20px 0px 0px 0px;
    }
}

@media (max-width: 576px) {
    .tmg {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

@media (max-width: 576px) {
    .size_c {
        width: 50%;
    }
}

@media (max-width: 576px) {
    .size_c2 {
        width: 40%;
    }
}
.timeline::before {
    border-radius: .25rem;
    background: #428DFF;
    bottom: 0;
    content: '';
    left: 0;
    margin: 0;
    position: absolute;
    top: -70px;
    width: 2px;
    height: 310px;
}

.size_c {
    width: 300px
}

.size_c2 {
    width: 300px
}
.iconimg {
    background: #fff;
    border-radius: 50%;
    font-size: 10px;
    height: 60px;
    margin-top: -80px!important;
    line-height: 30px;
    position: absolute;
    text-align: center;
    border: 1px solid #428dff;
    width: 60px!important;
}
.navbar.login-top{
    position: fixed;
    top: 0;
    z-index: 1020;
}
#welcome {
    background: #1A3781;
    padding: 50px 0;
}
#welcome h2 {
    color: #fff;
    font-family: 'ZapfHumnst', serif;
    font-size: 40px;
    margin-bottom: 3rem;
}
.homePage h1,.homePage h2,.homePage h3,.homePage h4,.homePage h5,.homePage h6{
    font-family: 'ZapfHumnst', serif!important;
}
.homePage div,.homePage span,.homePage p{
    font-family: 'AvenirLTStdBook'!important;
    font-size: 16px!important;
}
.contact-sec {
    background-color: #0F2867;
    padding: 45px 0;
}

.contact-sec .icon {
    color: #F0B33F;
    text-align: center;
    font-size: 18px;
}

.contact-sec h6 {
    color: #fff;
    font-family: 'ZapfHumnst', serif;
    font-size: 18px;
    margin: 15px 0px 10px 0px;
}

.contact-sec h5 a,.contact-sec h5 {
    color: #fff !important;
    font-family: inherit;
    font-size: 22px;
    text-decoration: none;
}

.contact-sec h5 a:hover {
    color: #F0B33F !important;
    text-decoration: none;
}
.contact-sec p {
    padding-top: 5px;
    margin: 0;
    font-size: 20px;
    color: #f0b33f;
    font-family: 'ZapfHumnst', serif;
}
.contact-sec .conIcon {
    color: #428dff;
    background-color: #fff;
    font-size: 25px;
    padding: 13px;
    border-radius: 50%;
    border: 1px solid;
    display: inline-flex;
}
.myFooter {
    background-color: #0f1b42;
    color: white;
    padding: 60px 30px;
}

.myFooter .nested-a {
    line-height: 1.2;
    margin-bottom: 15px;
    margin-top: -3px;
}

.myFooter .footer-sm-head {
    color: #f0b33f90;
    font-size: 14px;
    letter-spacing: 1.2px;
    font-weight: 600;
    line-height: 2.5;
}

.myFooter .nested-a a {
    font-size: 11px;
    letter-spacing: 0.5px;
}

.myFooter p {
    color: #ffffff80;
    font-size: 14px!important;
}

.myFooter h5 {
    color: #f0b33f90;
    font-size: 14px;
    letter-spacing: 1.2px;
}

.myFooter ul {
    list-style-type: none;
    padding-left: 0;
    line-height: 2.2;
}

.myFooter a {
    color: #ffffff80;
    text-decoration: none;
    font-size: 14px;
}

.myFooter a:hover,
.myFooter a:focus {
    text-decoration: none;
    color: white;
}
@media (min-width: 320px) {
    .footer-large {
        display: none;
    }

    .footer-small {
        display: block;
    }

    .footer-small img{
        width: 290px;
    }
}
@media (min-width: 576px) {
    .footer-large {
        display: none;
    }

    .footer-small img{
        width: 290px;
    }

    .footer-small {
        display: block;
    }
}
@media (min-width: 768px) {
    .footer-large {
        display: none;
    }

    .footer-small img{
        width: 450px;
    }

    .footer-small {
        display: block;
    }
}

@media (min-width: 1023.5px) {
    .footer-large {
        display: block;
    }

    .footer-small {
        display: none;
    }
}

@media (min-width: 1200px) {

    .footer-large {
        display: block;
    }

    .footer-small {
        display: none;
    }
}
.footer {
    background-color: #0F2867;
}
.footer {
    position: fixed;
    bottom: 0;
    z-index: 1020;
}
.footerbottom{
    padding: 5px 0px;
    margin: 0;
}
.footerbottom p {
    color: #fff;
    margin-bottom: 0px;
    font-size: 14px;
}
.footerbottom svg {
    color: #fff;
    font-size: 28px;
    padding: 0px 5px;
    text-align: right;
}
.btn-login {
    color: #fff!important;
    background-color: #002173!important;
    border-color: #002173!important;
    padding: 7px 25px!important;
    border-radius: 25px!important;
}
.login-aside{
    background-color:#EEF1F6;
    min-height: auto;
}
.login-aside.signup-aside{
    background-color: #1A3781;
    padding-top: 0;
    min-height: auto;
}
.login-aside .aside-img img{
    margin-top: 22px;
    height: 137px;
}
.aside-img {
    min-height: 173px!important;
}

.login-form h1{
    font-size: 30px;
    letter-spacing: normal;
    font-family: 'ZapfHumnst', serif;
    color: #000;
}
.login-form h1.custom-h1{
    font-size: 22px;
    color: #002173;
    font-weight: bold;
}
.login-form p{
    letter-spacing: normal;
    font-family: 'AvenirLTStdBook', sans-serif;
}

.login-form a{
    letter-spacing: normal;
    color: #f0b33f;
    text-decoration: none;
}
.login-form a:hover {
    color: #002173;
    text-decoration: none;
}
.login-form .form-group {
    margin-bottom: 1rem;
}
.login-form label{
    font-size: 14px;
    font-weight: bold;
    color: #212529;
    margin-bottom: 5px;
}

.signup-aside .box-aside {
    margin: 10px 0px;
}
.signup-aside .box-aside .box{
    height: 55px;
    width: 55px;
    background-color: #fff;
    border-radius: 50%;
    padding: 15px;
}
.signup-aside .box-aside .box img{
    width: 25px;
}
.signup-aside .box-aside p{
    margin-bottom: 0;
    margin-left: 0px;
    margin-top:5px;
    font-size: 16px;
    color: #fff;
    letter-spacing: normal;
    font-weight: normal;
}
@media (min-width:576px) {
   
    .login-aside .aside-img img{
        margin-top: 30px;
        height: 137px;
    }
     /* .login-content.signup-content{
        width: 100%;
        max-width: 450px;
    } */
    /* .login-content .login-form {
        width: 100%;
        max-width: 400px;
        height: 100%;
    } */
     /* .login-aside.signup-aside{
         background-color: #1A3781;
         padding-top: 0;
         min-height: auto;
     } */
     /* .signup-col{
         height: auto;
         margin-top: 10px;
     } */
     .signup-aside .box-aside p{
        margin-bottom: 0;
        margin-left: 0px;
        margin-top:5px;
        font-size: 16px;
        color: #fff;
        letter-spacing: normal;
        font-weight: normal;
    }
 }
@media (min-width:768px) {
    /* .login-aside {
        width: 100%;
        max-width: 495px;
    } */
    /* .login-content {
        width: 100%;
        max-width: 500px;
    } */
    /* .login-content.signup-content{
        width: 100%;
        max-width: 500px;
    } */
    /* .login-content .login-form {
        width: 100%;
        max-width: 450px;
        height: 100%;
    } */
    .login-aside .aside-img img{
        margin-top: 35px;
        height: 250px;
    }
    /* .login-aside.signup-aside{
        min-height: calc(100vh - calc(4rem + -3px) - calc(3.45rem + -0.6px));
    }
    .login-aside{
        min-height: calc(100vh - calc(4rem + -3px) - calc(3.45rem + -0.6px));
    } */
    /* .signup-col{
        height: calc(100vh - calc(4rem + 1px) - calc(3.45rem + 8px));
        overflow-y: auto;
        overflow-x: hidden;
        margin-top: 10px;
    } */
    .signup-aside .box-aside p{
        margin-bottom: 0;
        margin-left: 15px;
        margin-top:5px;
        font-size: 18px;
        color: #fff;
        letter-spacing: normal;
        font-weight: bold;
    }
}
@media (min-width:992px) {
    .login-aside .aside-img {
        min-height: 410px;
    }
    .login-aside .aside-img img{
        margin-top: 30px;
        height: 350px;
    }
   /* .login-aside {
        width: 100%;
        max-width: 495px;
    }
    .login-content {
        width: 100%;
        max-width: 500px;
    }
    .login-content .login-form {
        width: 100%;
        max-width: 450px;
        height: 100%;
    }
    .signup-col{
        height: calc(100vh - calc(4rem + 1px) - calc(3.45rem + 8px));
        overflow-y: auto;
        overflow-x: hidden;
        margin-top: 10px;
    } */
    .signup-aside .box-aside .box{
        height: 60px;
        width: 60px;
        background-color: #fff;
        border-radius: 50%;
        padding: 15px;
    }
    .signup-aside .box-aside .box img{
        width: 30px;
    }
    .signup-aside .box-aside p{
        margin-bottom: 0;
        margin-left: 15px;
        font-size: 18px;
        color: #fff;
        letter-spacing: 1.5px;
        font-weight: bold;
        font-family: 'AvenirLTStdBook', sans-serif;
    }
}
@media (min-width:1400px) {
    /* .login-aside {
        width: 100%;
        max-width: 600px;
    } */
    .login-aside .aside-img img{
        height: 450px;
    }
}
.notfound-page{
    min-height: calc(100vh - calc(14rem + -3px) - calc(9.45rem + -0.6px));
}
.notfound-page h1{
    color: #002173;
    font-weight: 600;
    font-size: 24px;
}
.notfound-page p{
    color: #6c757d;
}
.notfound-page p a{
    color: #f0b33f;
}
.notfound-page p a:hover{
    color: #002173;
    text-decoration: none;
}
.notfound-page img{
    width: 50%;
}
@media (min-width:768px) {
    .notfound-page img{
        width: 18%;
    }
}
.form-control:focus {
    border-color: #002173!important;
    box-shadow: 0 0px 6px 1px rgb(0 33 115 / 25%)!important;
}
.btn-check:focus+.btn, .btn:focus {
    box-shadow: 0 0 5px 0.25rem rgb(0 33 115 / 25%)!important;
}
.ReactFlagsSelect-module_selectBtn__19wW7{
    height: 38px;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    appearance: none;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.ReactFlagsSelect-module_filterBox__3m8EU input {
    height: 38px;
    box-sizing: border-box;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
}
.anchorspace{
    display: block;
    height: 92px;
    margin-top: -92px;
    visibility: hidden;
}
.react-tel-input .form-control {
    display: block;
    width: 100%;
    height: auto !important;
    /* padding: 1rem 1.5rem; */
    padding-left: 48px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #3F4254;
    background-color: #F3F6F9;
    background-clip: padding-box;
    border: 1px solid #F3F6F9;
    border-radius: 0.42rem;
    box-shadow: none;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
}
.react-tel-input .flag-dropdown {
    background-color: #F3F6F9;
    border: 1px solid #F3F6F9;
}
.ReactFlagsSelect-module_selectBtn__19wW7{
    background-color: #F3F6F9;
    border-color: #F3F6F9;
    color: #3F4254;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    padding: 0.604em 1.5rem;
    height: auto !important;
    font-size: 14px!important;
}
.bannerbg {
    height: 45px;
    position: relative;
    background-image: url('../Images/bannerbg.png');
    background-color: #0F2867;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}
.bannerbg img{
    height: 35px;
}
@media (min-width: 576px){
    .bannerbg {
        height: 45px;
    }
    .bannerbg img{
        height: 35px;
    }
}
@media (min-width: 768px){
    .bannerbg {
        height: 75px;
    }
    .bannerbg img{
        height: 70px;
    }
}